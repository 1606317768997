import { Card } from 'components/SignInForm/Card'
import { Button, TextField, CircularProgress, Grid, Typography, useTheme } from '@achieve/ascend'
import styles from './OnboardingEmail.module.scss'
import { useContext, useState } from 'react'
import { Check } from 'react-feather'
import { encrypt } from 'utils/ote/crypto/browser'
import { useRouter } from 'next/router'
import { useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'
import { Image } from 'components/Image'
import logger from '@achieve/cx-logger'
import { DashboardDialog } from 'components/SignInForm/DashboardDialog/DashboardDialog'
import { isValidEmail } from 'utils/form'
import { useTriggerEvents } from 'hooks/useTriggerEvents'
import { handleTrackAndReactEvent } from 'utils/analytics'
import { LinkButton } from 'components/Contentful'
import { AnalyticsContext } from 'providers/AnalyticsProvider'

const MainText = ({ content }) => {
  const theme = useTheme()
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)

  return (
    <div className={styles['main-text']}>
      <Grid item className={styles['title']}>
        <Typography
          fontWeight="bold"
          variant="headingLg"
          component="h1"
          color={theme.ascend.colors.content.primary}
        >
          {content.title}
          <span
            style={{
              color: theme.ascend.colors.content.positive,
              padding: '0 6px',
            }}
          >
            {content.emphasisTitle}
          </span>
          {content.subtitle}
        </Typography>
      </Grid>
      <Grid item container className={styles['bullet-point-container']}>
        {' '}
        <Grid item className={styles['bullet-point-row']}>
          <Check color={theme.ascend.colors.content.positive} />{' '}
          <Typography variant="bodyMd" className={['']}>
            {content.bulletPointOne}{' '}
          </Typography>{' '}
        </Grid>{' '}
        <Grid item className={styles['bullet-point-row']}>
          <Check color={theme.ascend.colors.content.positive} />
          <Typography variant="bodyMd">{content.bulletPointTwo}</Typography>{' '}
        </Grid>{' '}
        <Grid item className={styles['bullet-point-row']}>
          <Check color={theme.ascend.colors.content.positive} />
          <Typography variant="bodyMd">{content.bulletPointThree}</Typography>{' '}
        </Grid>{' '}
      </Grid>
      <Grid item className={styles['trust-pilot-container']}>
        <Image
          src={isMobile ? content.trustPilot_svg.mobile.src : content.trustPilot_svg.desktop.src}
          alt={content.trustPilot_svg.alt}
          layout="fill"
        />
      </Grid>
      <div className={styles['corner-sun']}>
        <Image
          src={isMobile ? content.corner_svg.mobile.src : content.corner_svg.desktop.src}
          alt={content.corner_svg.alt}
          layout="fill"
        />
      </div>
    </div>
  )
}

const OnboardingEmail = ({ encryptionKey, content }) => {
  const { fireAndForget } = useTriggerEvents()
  const {
    state: { quiz_page, quiz_score },
  } = useContext(AnalyticsContext)

  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [error, setError] = useState(false)
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const router = useRouter()
  const theme = useTheme()
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const errorMessage = 'Please enter a valid email'

  const onSubmit = async (event) => {
    // Prevent page reload
    event.preventDefault()
    // No submit when email is blank
    if (!email) return
    // Check for valid email
    if (!isValidEmail(email)) {
      setEmailError(true)
      return
    }
    try {
      setHasSubmitted(true)
      const track = {
        actionType: 'buttonClicked',
        click_id: 'Achieve | Continue',
        click_position: '0',
        click_text: 'Continue',
        click_type: 'Button Click',
        eventName: 'Onboarding Form Submit Click',
        event_name: `Achieve-Web | ${quiz_page} - Onboarding Form Submit Click`,
        page_name: `Achieve-Web | ${quiz_page}`,
        quiz_score: quiz_score,
        flow_event: 'Achieve-Web | Lead CTA Click',
        flow_name: 'Achieve-Web | Member Dashboard',
        flow_step_name: `Achieve-Web | Form`,
        nav_link_section: 'Form',
        page_category: 'form',
        tealium_event: 'button_click',
        track_event: 'form_click',
      }
      fireAndForget({
        event_type: track?.event_type,
        ...handleTrackAndReactEvent(event, track),
      })
      // Encrypt email with util to hide email in url
      const state = { email }
      const encryptedState = await encrypt(state, encryptionKey)
      // Reload the page with the encrypted email in the query string
      const url = new URL(location.href)
      // searchParams.set() URI Encodes for you
      url.searchParams.set('state', encryptedState)
      url.searchParams.set('utm_medium', 'organic')
      url.searchParams.set('utm_media', 'organic')
      url.searchParams.set('utm_source', 'achieve_organic_mxp')
      url.searchParams.delete('p')
      url.pathname = '/dashboard/onboarding/start'
      router.replace(url)
    } catch (error) {
      setHasSubmitted(false)
      logger.error('/onboarding handleSubmit catch', { error })
      const track = {
        eventName: 'Onboarding Form handleSubmit failure',
        event_name: `Achieve-Web | ${quiz_page} - Onboarding Form handleSubmit failure`,
        page_name: `Achieve-Web | ${quiz_page}`,
        quiz_score: quiz_score,
        flow_name: 'Achieve-Web | Member Dashboard',
        flow_step_name: `Achieve-Web | Form`,
        nav_link_section: 'Form',
        page_category: 'form',
        tealium_event: 'event',
        track_event: 'event',
        error: JSON.stringify(error),
      }
      fireAndForget({
        event_type: track?.event_type,
        ...handleTrackAndReactEvent(event, track),
      })
      setError(true)
    }
  }

  const handleChange = (e) => {
    const { value } = e.target
    setEmailError(false)
    setEmail(value)
  }

  const closeModalAndResetStates = () => {
    const track = {
      actionType: 'buttonClicked',
      click_id: 'Achieve | Continue',
      click_position: '0',
      click_type: 'Button Click',
      click_text: 'Continue',
      click_url: `none`,
      eventName: 'Onboarding Index Error Dialog Close Click',
      event_name: `Achieve-Web | ${quiz_page} - Onboarding Index Error Dialog Close Click`,
      page_name: `Achieve-Web | ${quiz_page}`,
      quiz_score: quiz_score,
      flow_event: 'Achieve-Web | Lead CTA Click',
      flow_name: 'Achieve-Web | Member Dashboard',
      flow_step_name: `Achieve-Web | Form`,
      nav_link_section: 'Form',
      page_category: 'form',
      tealium_event: 'button_click',
      track_event: 'form_click',
    }
    fireAndForget({
      event_type: track?.event_type,
      ...handleTrackAndReactEvent(event, track),
    })
    setError(false)
    setEmailError(false)
    setHasSubmitted(false)
  }

  return (
    <>
      <Grid
        container
        sx={{ backgroundColor: isMobile ? 'white' : theme.ascend.colors.background.accentLight }}
        className={styles['container']}
      >
        <div className={styles['container-child']}>
          <Grid
            item
            className={styles['main-text-container']}
            style={{ backgroundColor: content.mainTextBackground }}
          >
            <MainText content={content} />
          </Grid>

          <Grid item className={styles['grid-item-form-container']}>
            <Card variant={isMobile ? 'none' : 'none'} className={styles['card']}>
              <div className={styles['grid-item-form']}>
                <Grid item className={styles['subheader']}>
                  <Typography variant="headingMd" color={theme.ascend.colors.content.primary}>
                    {content.onboardingTitle}
                  </Typography>
                </Grid>
                <Grid item>
                  <form onSubmit={onSubmit}>
                    <div className={styles['label-padding']}>
                      <Typography variant="labelMd" color={theme.ascend.colors.content.primary}>
                        {content.emailTitle}
                      </Typography>
                    </div>
                    <TextField
                      error={emailError}
                      fullWidth
                      helperText={emailError && errorMessage}
                      onChange={(e) => handleChange(e)}
                      placeholder="Enter your email"
                      required
                      autoFocus
                      type="email"
                      value={email}
                    />
                  </form>
                </Grid>
                <Grid item className={styles['disclosure']}>
                  <Typography
                    variant={isMobile ? 'bodyXs' : 'bodySm'}
                    color={theme.ascend.colors.content.secondary}
                  >
                    {content.consentIntroText}{' '}
                    <a
                      href={content.consent.links.privacyNotice}
                      style={{ color: theme.ascend.colors.content.secondary }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {content.privacyNotice}
                    </a>
                    {content.consent.links.eSignConsent ? ' ' : ' and '}
                    <a
                      href={content.consent.links.privacyPolicy}
                      style={{ color: theme.ascend.colors.content.secondary }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {content.privacyPolicy}
                    </a>{' '}
                    {content.consent.links.eSignConsent && (
                      <a
                        href={content.consent.links.eSignConsent}
                        style={{ color: theme.ascend.colors.content.secondary }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {content.eSignConsent}{' '}
                      </a>
                    )}
                    {content.consentDisclosuresCommsText}{' '}
                    <a
                      href={content.consent.links.termsOfUse}
                      style={{ color: theme.ascend.colors.content.secondary }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {content.termsOfUse}
                    </a>
                  </Typography>
                </Grid>
                <Button fullWidth onClick={onSubmit} variant="contained">
                  {hasSubmitted ? (
                    <CircularProgress size={25.19} style={{ color: 'white' }} />
                  ) : (
                    content.submitText
                  )}
                </Button>

                <div className={styles['bottom-text']}>
                  <Typography variant="bodyMd">{content.returningUserText}</Typography>
                  <LinkButton
                    content={{ linkHref: '/signin', linkText: content.signInText }}
                    typographicOptions={{ variant: isMobile ? 'displayXs' : 'headingSm' }}
                    variant="text"
                  />
                </div>
              </div>
            </Card>
          </Grid>
        </div>
      </Grid>
      {error && (
        <DashboardDialog
          dialogTitleText={content.dialogTitleText}
          dialogContentText={content.dialogContentText}
          modalIsOpen={true}
          primaryCtaHandleClick={() => closeModalAndResetStates()}
          primaryCtaText={content.primaryCtaText}
          oneButtonDialog={true}
          handleCloseDialog={() => closeModalAndResetStates()}
        />
      )}
    </>
  )
}

export { OnboardingEmail }
