const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line no-useless-escape

/**
 * Determine if an email string is valid
 * @param {string} email
 * @returns {boolean}
 */
export function isValidEmail(email) {
  return Boolean(email?.match(EMAIL_REGEX))
}
