/**
 * Written with the Web Crypto API for the browser.
 * @param {Object} data Object to encrypt
 * @param {String} base64Key The encryption key in base64
 * @returns {String} The encrypted string
 */
export const encrypt = async (data = {}, base64Key) => {
  if (!Object.keys(data)) {
    throw new Error('encrypt() data object empty')
  }

  // Ensure key was specified
  if (!base64Key) {
    throw new Error('encrypt() base64key must be specified')
  }

  // Convert Object to string
  const plainText = JSON.stringify(data)

  // Convert the key from a base64 string to a Uint8Array
  const keyBytes = Uint8Array.from(atob(base64Key), (c) => c.charCodeAt(0))

  // Generate a random initialization vector
  const iv = window.crypto.getRandomValues(new Uint8Array(16))

  // Convert the text to be encrypted to a Uint8Array
  const textBytes = new TextEncoder().encode(plainText)

  // Define the encryption algorithm
  const algorithm = {
    name: 'AES-CBC',
    iv: iv,
  }

  // Import the key
  const cryptoKey = await window.crypto.subtle.importKey('raw', keyBytes, algorithm, false, [
    'encrypt',
  ])

  // Encrypt the text
  const cipherText = await window.crypto.subtle.encrypt(algorithm, cryptoKey, textBytes)

  // Convert the ciphertext to a hex string
  const cipherTextBytes = new Uint8Array(cipherText)
  const cipherTextHex = Array.from(cipherTextBytes)
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('')

  // Convert the iv to a hex string
  const ivHex = Array.from(iv)
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('')

  // Return the iv and cipherText as a colon-separated hex string
  return `${ivHex}:${cipherTextHex}`
}
