import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Typography,
  useTheme,
} from '@achieve/ascend'
import styles from './DashboardDialog.module.scss'
import { useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'

function DashboardDialog({
  modalIsOpen = false,
  handleCloseDialog = () => window.location.reload(),
  dialogTitleText = '',
  dialogContentText = '',
  dialogActionRequiredText = '',
  primaryCtaText = 'Continue',
  secondaryCtaText = 'Cancel',
  primaryCtaHandleClick = () => {},
  secondaryCtaHandleClick = () => {},
  oneButtonDialog = false,
}) {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.md)
  const theme = useTheme()

  return (
    <Dialog
      onClose={handleCloseDialog}
      open={modalIsOpen}
      maxWidth={'xs'}
      slotProps={{ backdrop: { style: { backgroundColor: 'rgba(31, 31, 35, .75)' } } }}
    >
      <DialogTitle
        onClose={handleCloseDialog}
        sx={{
          textAlign: 'left !important',
          padding: isMobile ? '15px 20px 10px !important' : '18px 32px 14px !important',
        }}
      >
        <Typography
          variant="ascendBodyLg"
          fontWeight="bold"
          color={theme.ascend.colors.content.primary}
        >
          {dialogTitleText}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={styles['telephone-link']}>
          <Typography variant="ascendBodXs" color={theme.ascend.colors.content.secondary}>
            {dialogContentText}
          </Typography>
        </DialogContentText>
        {dialogActionRequiredText && (
          <DialogContentText
            className={styles['telephone-link']}
            sx={{ paddingBottom: '16px', fontWeight: 'bold' }}
          >
            {dialogActionRequiredText}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        {oneButtonDialog ? (
          <Button
            onClick={primaryCtaHandleClick}
            size="medium"
            sx={isMobile ? { width: '100%' } : {}}
          >
            {primaryCtaText}
          </Button>
        ) : (
          <>
            <Button
              variant="text"
              onClick={secondaryCtaHandleClick}
              size={isMobile ? 'xsmall' : 'medium'}
              className={styles['logout-button']}
            >
              {secondaryCtaText}
            </Button>
            <div style={{ marginRight: isMobile ? '-16px' : '32px' }}></div>
            <Button onClick={primaryCtaHandleClick} size={isMobile ? 'xsmall' : 'medium'}>
              {primaryCtaText}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}

export { DashboardDialog }
