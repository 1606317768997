import { Card as SunbeamCard } from '@achieve/ascend'
import classNames from 'classnames'
import styles from './Card.module.scss'
import { content } from './content'

function Card({ className, ...props }) {
  return (
    <SunbeamCard
      className={classNames(styles['card'], className)}
      variant={content.style.variant}
      // the spread must come last to allow for overriding `variant`
      {...props}
    />
  )
}

export { Card }
