import { LinkButton, MediaImageStandard, Typography } from 'components/Contentful'
import styles from './ArticleListCarousel.module.scss'
import { Grid } from '@achieve/ascend'
import { Carousel } from 'components/Carousels'
import { Eyebrow } from 'components/Eyebrow'
import { AchieveLink } from 'components/AchieveLink'
import { getSlug } from 'utils/conversions'
import { AuthorAvatar } from 'components/AuthorAvatar'
import { AUTHORS_ROUTE, LEARN_ROUTE } from 'constants/navigation'
import { PlayButtonBackground } from 'components/PlayButtonBackground'

/**
 * Renders the horizontal especific list of articles
 *
 * @component
 * @param {Object} props.title - The Title of section list.
 * @param {Object} props.relatedArticles - The List of articles. accept query from contenfull client, or graphl structure data
 * @returns {JSX.Element} The rendered component. This component will render a list with a Title and List of articles(three) on desktop and carousel on mobile.
 *
 */
function ArticleListCarousel({ title = 'Related Articles', relatedArticles, linkCta, config }) {
  function RelatedArticleCard({ item, index }) {
    const {
      title,
      slug: articleSlug,
      primaryCategory,
      author,
      mainImage,
      metaDescription,
    } = item?.fields ?? item ?? {}
    const { slug: authorSlug, authorName, authorPhoto } = author?.fields ?? author ?? {}
    const isVideo = mainImage?.__typename === 'VideoPlayer'

    const linkHrefArticle = `${LEARN_ROUTE}/${getSlug(primaryCategory)}/${articleSlug}`.replace(
      '//',
      '/'
    )
    const hrefCategory = `${LEARN_ROUTE}/${getSlug(primaryCategory)}`

    const LinkItem = ({ children, 'data-testid': dataTestId }) => {
      return (
        <AchieveLink
          href={linkHrefArticle}
          track={{
            list_name: `ARTICLE LINK`,
            position: index,
            click_id: `Achieve Web | ${title}`,
            click_text: title,
            click_url: articleSlug,
          }}
          className={styles['card-link']}
          data-testid={dataTestId || 'related-article-card-link'}
        >
          {children}
        </AchieveLink>
      )
    }
    return (
      <Grid
        key={`releated-article-${index}`}
        container
        spacing={1}
        className={styles['article-card-container']}
        data-eyebrows-blue={config?.eyebrowsBlue}
        data-testid={`related-article-carousel-card-${index}`}
      >
        {mainImage && (
          <Grid item xs={12} className={styles['img-container-grid']}>
            <div className={styles['img-container']}>
              <LinkItem data-testid="related-article-card-image-link">
                <>
                  <MediaImageStandard
                    content={
                      mainImage?.fields?.mediaContent ??
                      mainImage?.mediaContent ??
                      mainImage.thumbnail
                    }
                    data-testid="related-article-card-image"
                  />
                  {isVideo && <PlayButtonBackground fontSize={64} />}
                </>
              </LinkItem>
            </div>
          </Grid>
        )}
        <Grid item xs={12} className={styles['article-card-eyebrow-grid']}>
          <AchieveLink
            href={hrefCategory}
            className={styles['eyebrow-link']}
            data-testid="related-article-card-eyebrow-link"
          >
            <Eyebrow
              className={styles['article-card-eyebrow']}
              black
              content={primaryCategory}
              data-testid="related-article-card-eyebrow-text"
            />
          </AchieveLink>
        </Grid>
        {title && (
          <Grid item xs={12}>
            <LinkItem data-testid="related-article-card-title-link">
              <Typography
                content={title}
                variant="headingMd"
                fontWeight="medium"
                data-testid="related-article-card-title"
              />
            </LinkItem>
          </Grid>
        )}
        {!config?.hiddenDescription && (
          <Grid item xs={12} marginBottom={1}>
            <Typography
              content={metaDescription}
              variant="bodySm"
              data-testid="related-article-card-description"
            />
          </Grid>
        )}
        {authorName && (
          <Grid
            item
            xs={12}
            className={styles['article-card-author-container']}
            data-testid="related-article-card-author-container"
          >
            <AuthorAvatar
              authorPhoto={authorPhoto}
              index={0}
              href={`${AUTHORS_ROUTE}/` + (authorSlug || getSlug(authorName))}
              authorName={authorName}
              priority={false}
            />
          </Grid>
        )}
      </Grid>
    )
  }

  return (
    <Grid container xs={12} gap={2} paddingTop={4} paddingBottom={4}>
      {title && (
        <Grid item xs={12} marginBottom={2}>
          <Typography content={title} variant="headingMd" />
        </Grid>
      )}
      <Grid item xs={12} display={{ xs: 'block', lg: 'none' }}>
        <Carousel
          carouselItems={relatedArticles}
          className={styles['releated-articles-wrapper']}
          classNameContainer={styles['releated-articles-container']}
          classNameItems={styles['releated-articles-items']}
          itemComponent={RelatedArticleCard}
          variant="standard"
          idPrefix={`releated-articles`}
          withIndicators={true}
          classNameControls={styles['carousel-wrapper-controls']}
          classNameIndicatorDots={styles['flat-carousel-indicators']}
        />
      </Grid>
      <Grid item xs={12} display={{ xs: 'none', lg: 'block' }}>
        <Grid container spacing={4}>
          {relatedArticles?.map((item, index) => {
            return (
              <Grid item xs={4} key={index}>
                <RelatedArticleCard item={item} index={index} />
              </Grid>
            )
          })}
        </Grid>
      </Grid>
      {linkCta && (
        <Grid item xs={12} marginBottom={2} className={styles['releated-articles-cta']}>
          <LinkButton
            size="large"
            content={linkCta}
            data-testid="article-cta"
            typographicOptions={{ variant: 'labelMd' }}
            className={styles['releated-articles-cta-button']}
          />
        </Grid>
      )}
    </Grid>
  )
}

export { ArticleListCarousel }
